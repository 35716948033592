var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentRole === 4 || _vm.currentRole === 5)?_c('div',{attrs:{"id":"course-management"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"pa-10 pt-5"},[_c('h2',{staticClass:"mb-10"},[_vm._v("Course management")]),(_vm.isLoading)?_c('v-progress-linear',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.isLoading)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filtered,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-header":"","hide-default-footer":"","fixed-header":"","height":"65vh"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"text-start font-weight-bold",style:('width:' + header.width + '; min-width:' + header.width + ';'),attrs:{"role":"columnheader","scope":"col","aria-label":"No"}},[_c('span',[_vm._v(_vm._s(header.text))])])}),0)]),_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('th',{key:header.text},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_vm._e()])}),0)]}},{key:"item.no",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.photo",fn:function(ref){
var item = ref.item;
return [(item.photo)?_c('img',{staticClass:"d-flex align-center",staticStyle:{"object-fit":"contain"},attrs:{"src":'data:image/jpeg;base64,' + item.photo,"height":"60","width":"100%"}}):_c('v-icon',{attrs:{"size":"40"}},[_vm._v(" image ")])]}},{key:"item.inactive",fn:function(ref){
var item = ref.item;
return [(item.inactive == 'Inactive')?_c('v-chip',{attrs:{"small":"","color":"rgba(247, 119, 26, 0.1)"}},[_vm._v(_vm._s(item.inactive))]):_c('v-chip',{attrs:{"small":"","color":"rgba(36, 106, 255, 0.1)"}},[_vm._v(_vm._s(item.inactive))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"size":"18","color":"primary"},on:{"click":function($event){return _vm.viewDetail(item.levelID, item.inactive)}}},[_vm._v("visibility")])]}}],null,true)}):_vm._e()],1)])],1),(!_vm.isLoading)?_c('div',{staticClass:"w-100 pl-10 pr-10",attrs:{"color":"#fff"}},[_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticStyle:{"height":"32px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"md":"4"}},[(_vm.filtered.length != 1)?_c('h3',[_vm._v("Total: "+_vm._s(_vm.filtered.length)+" courses")]):_c('h3',[_vm._v("Total: "+_vm._s(_vm.filtered.length)+" course")])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"elevation":"0","total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }